import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Section, BreadCrumb, Tags, ComingSoon } from '../components/Section'
import { Link } from "gatsby"
import styled from 'styled-components'
import { StaticImage } from "gatsby-plugin-image"
import LetastArticles from "../components/LetastArticles"
import BreakpointDown from '../components/Media/BreakpointDown'
import SecondaryLinkButton from '../components/Button/SecondaryLinkButton'

const SectionBoxShadow = styled(Section)`
 box-shadow: 0px 20px 85px #00000033;
 z-index:2;
`
const PageTitle = styled.h1`
  margin-bottom:10px;
  text-transform: capitalize;
`
const PageTitleWrapper = styled.div`
 text-align:center;
 margin-top:50px;
 max-width:900px;
 margin-left:auto;
 margin-right:auto;
 
 ${BreakpointDown.lg`
   margin-top:30px;
 `}
 ${BreakpointDown.sm`
   margin-top:15px; 
 `}

`


const CardImg = styled.div`
  position: relative;
  max-width:100%;
  width:100%;
  margin-top:26px;
  margin-left:auto;
  margin-right:auto;
  text-align:center;
 /*  .gatsby-image-wrapper{
    width:100%;
  } */
  ${BreakpointDown.lg`
   margin-top:0;
  `}
`

const TraingleShape = styled.div`
  width: 0;
  height: 0;
  display: block;
  border-top: 20px transparent solid;
  border-right: 20px transparent solid;
  border-left: 20px transparent solid;
  border-bottom: 20px #fff solid;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1px;
  margin-left: auto;
  margin-right: auto;
  z-index: 3; 
`
const BlogDetailsContent = styled.div`
 margin-top:40px;

 ${BreakpointDown.lg`
   margin-top:20px;
 `}

 p+p{
   margin-top:20px;
 }
`
const BlogWrapper = styled.div`
 max-width:1024px;
  margin-left:auto;
  margin-right:auto;
`

//Letast BLogs


const Card = styled.div`
  padding: 70px 50px ;
  background:#fff;
  box-shadow: 0px 20px 45px rgb(0, 0, 0, 0.20);
  /* @media (min-width:2200px){
    padding: 20px 15px;
  } */
  @media(max-width:1440px){
    padding: 20px 14px; 
  }
  ${BreakpointDown.lg`
   text-align:center;
 `}
  .btn{
    margin-top:15px;
  }
`
const CardTitle = styled.div`
 margin-bottom:6px;
 font-weight:bold;
`
const CardImg1 = styled.div`
  position: relative;
  max-width:100%;
  width:100%;
  margin-top:26px;
  .gatsby-image-wrapper{
    width:100%;
  }
`
const Date = styled.div`
  color:#666666;
  font-size:10px;
  letter-spacing: 2px;
  text-transform: uppercase;
  @media (min-width:2200px){
    font-size:14px;
  }
`
const SectionHeader = styled.div`
 text-align:center;
 .h1{ margin-bottom:0px;}
 ${BreakpointDown.md`
 p{
   margin-bottom:0;
 } 
 `}
`
const Title = styled.h2`

`

const Blogs = styled.div`
 display: flex;
 margin-inline:-15px;
 flex-wrap:wrap;
 margin-top:50px;
`
const BlogItems = styled.div`
 flex:0 0 auto;
 width:33.3333%;
 padding-inline:15px;
 @media(max-width:767px){
   width:100%;
  & + &{
    margin-top:20px;
  }
 }
`


function BlogPage() {
  return (
    <Layout pageName="everything-you-need-to-know-about-getting-an-access-control-system">
      <SEO title="Everything You Need to Know About Getting an Access Control System" description="Access control systems bring your business to the next level by enhancing your security, increasing your productivity, creating better experiences for your customers, and growing with you as your business grows." />
      <Section pt="156px" pb="100px" xpt="80px" xpb="60px" mpt="60px" mpb="40px" bgColor="#F8F8F8">
        <TraingleShape />
        <div className="container">
          <BreadCrumb>
            <Link to="/">Home</Link> <Link to="/blog">Blog</Link><Link to="/#">Everything You Need to Know About Getting an Access Control System</Link>
          </BreadCrumb>
          <PageTitleWrapper>
            <PageTitle className="h2">Everything You Need to Know About Getting an Access Control System</PageTitle>
            <Tags to="/">Access Control Systems</Tags> <Tags to="/">Business Safet and Security</Tags>
          </PageTitleWrapper>
        </div>
      </Section>
      <SectionBoxShadow bgColor="#ffffff" pt="84px" pb="60px" xpt="60px" mpb="40px" mpt="40px">
        <div className="container">

          <BlogWrapper>
            <CardImg>
              <StaticImage
                src="../images/blog_1_blog-detail.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Access Control Systems"
                placeholder="blurred"
              />
            </CardImg>
            <BlogDetailsContent>
              <p>Is your business growing? That’s great! Now you need to protect it and prepare for further growth! The best way to accomplish that is to get a premium access control system from the best access control installers around. WYN Technologies’ access control systems bring your business to the next level by enhancing your security, increasing your productivity, creating better experiences for your customers, and growing with you as your business grows.</p>
              <h2>Traditional or Digital: What’s Right for You?</h2>
              <p>An access control system is the best way to protect your business. WYN Technologies provides top-notch access control systems to meet your every need! But what are your needs? Every company is different, so we suggest reviewing these important questions to determine which access control system is best for you.</p>
              <ul>
                <li>
                  <strong>What are my security needs?</strong>
                  <p>This is the best place to start. You need to decide if you simply want to secure the outside of your building, or if you require interior spaces to also be secured. Do you want only the entrances secured? What about the building’s perimeter? When thinking about your security needs, you should spend a lot of time thinking about the physical space your company occupies.</p>
                </li>
                <li>
                  <strong>What options are out there?</strong>
                  <p>After you’ve decided which parts of your business should be secured, you need to determine how you’re going to accomplish that. There are two main types of access control systems. The first type is on-site, and the second is cloud-based. On-site systems require physical hardware and a dedicated team to maintain them. Cloud-based systems are controlled entirely from a smartphone or wireless device.</p>
                </li>
                <li>
                  <strong>What kind of key should I have?</strong>
                  <p>You also need to decide whether you want a traditional access method like an access pin or a keycard or if you want something even more secure. Keycards are convenient, but they can be easily copied. You get more security with biometric access systems. These systems use fingerprint or retina scans as a key. However, these options might be more than a small business would require. That's why there’s an increasingly popular third option: Digital “keys” in the form of a mobile sign-on.</p>
                </li>
                <li>
                  <strong>How will my access control system grow with me?</strong>
                  <p><Link to="/access-control-technology">Security system technologies</Link> are improving every day, so it’s important to know if your new system can grow with you.</p>
                  <p>The first question should be if any of the current hardware you already own can be used in conjunction with your new system. For example, do you already have compatible video cameras? Can you reuse the same control panels?</p>
                  <p>The second question is whether this system has the flexibility to grow if your business were to expand suddenly. To ensure flexibility, you need an access control system. WYN Technologies offers industry-standard access control systems that are proven to keep up! No matter how quickly technology evolves.</p>
                </li>
              </ul>
              <h2>Does My Business Really Need an Access Control System?</h2>
              <p>If you’re thinking about making your business more secure, you need an access control system from the best access control installers around. Access control systems from WYN Technologies benefit you and your company in three significant ways: enhanced security, increased productivity, and improved visitor experiences. It’s what you need to take your company to the next level.</p>
              <ul>
                <li>
                  <strong>Enhance Your Security</strong>
                  <p>The most obvious benefit of access control installation is that it will increase your overall security. Since the system is cloud-based, you won’t have to worry about everything that could go wrong with a traditional on-site system. Never again will you lose sleep over lost key cards and unexpected visitors. With an access control system, only those with approved credentials will be allowed in.</p>
                </li>
                <li>
                  <strong>Increase Your Productivity</strong>
                  <p>Thanks to WYN Technologies streamlined access control systems, you will be able to monitor your employees’ habits better. This will help you stop any unproductive behavior, such as unauthorized breaks, and encourage your employees to clock in and out. Proper access control system installation also eliminates the long lines that can form when employees use traditional sign-in methods.</p>
                </li>
                <li>
                  <strong>Improve Your Visitors’ Experiences</strong>
                  <p>Having visitors can be a critical facet for your company, but how do you manage them? It’s hard to plan for unexpected visitors, and accounting for them once they are there can take time out of an already busy day. <Link to="/access-control-installation-and-hardware-sales">Access control systems</Link> eliminate that problem by having state-of-the-art scheduling and visitor management features. These systems give visitors a way to sign in independently and allow your receptionists to speed through the check-in process since they’ll already be expecting them.</p>
                </li>
                <li>
                  <strong>It Gets Better</strong>
                  <p>The reasons for getting an access control system are virtually endless! We covered the top three, but there are still others worth mentioning:</p>
                  <p>-    Access control systems keep your sensitive information secure!</p>
                  <p>-    Access control systems reduce inventory theft!</p>
                  <p>-    You can access the management technology remotely!</p>
                </li>
              </ul>
              <h2>Get Upgraded with WYN Technologies</h2>
              <p>Access control systems from <Link to="/">WYN Technologies</Link> provide the security you need and so much more. These premium security and access management systems upgrade your business and help you reach your fullest potential. We pride ourselves on putting our customers’ growth first; it’s our top priority. We have a solid moral foundation, and we are determined to bring our clients’ dreams to fruition. Call us today at <a href="tel:3368990555">(336) 899-0555</a> and let us take you to the next level.</p>
            </BlogDetailsContent>
          </BlogWrapper>
        </div>
      </SectionBoxShadow>
      <Section pt="80px" mpt="40px" bgColor="#F8F8F8" pb="50px" mpb="40px" xxpt="100px" xxpb="100px">
        <div className="container">
          <SectionHeader>
            <Title className="h1">Latest Articles & Blogs</Title>

          </SectionHeader>
          <Blogs>
            <BlogItems>
              <Card> 
                    <Date>8 July, 2021</Date>
                    <CardTitle to="/move-your-business-into-the-future-with-voip-phone-systems" className="h6">Move Your Business Into the Future with VoIP Phone Systems</CardTitle>
                    <Tags to="/">Phone Systems</Tags> <Tags to="/">Business Communication</Tags>
                    <CardImg>
                      <StaticImage
                        src="../images/blog_3_blog-card.jpg"
                        quality={100}
                        formats={["AUTO", "WEBP", "AVIF"]}
                        alt="VoIP Phone Systems blog Card"
                        placeholder="blurred"
                      />
                    </CardImg>
                    <Link to="/move-your-business-into-the-future-with-voip-phone-systems"><SecondaryLinkButton text="Read Full Blog" /></Link>
                  </Card>
            </BlogItems>
            <BlogItems>
              <Card>
                    <Date>8 July, 2021</Date>
                    <CardTitle to="/get-the-right-point-of-sale-system-for-your-company-with-wyn-technologies" className="h6">Get the Right Point-of-Sale System for Your Company with WYN Technologies</CardTitle>
                    <Tags to="/">Point-of-Sale Systems</Tags> <Tags to="/">Retail Technology</Tags>
                    <CardImg>
                      <StaticImage
                        src="../images/blog_2_blog-card.jpg"
                        quality={100}
                        formats={["AUTO", "WEBP", "AVIF"]}
                        alt="VoIP Phone Systems blog Card"
                        placeholder="blurred"
                      />
                    </CardImg>
                    <Link to="/get-the-right-point-of-sale-system-for-your-company-with-wyn-technologies"><SecondaryLinkButton text="Read Full Blog" /></Link>
                  </Card>
            </BlogItems>
          </Blogs>
        </div>
      </Section>
    </Layout>
  )
}
export default BlogPage
